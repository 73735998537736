@tailwind base;
@tailwind components;
@tailwind utilities;

.parallax-banner,
.intro-text-lax {
  height: 50vh !important;
}

.home-hero .parallax-banner-layer-0 {
  background-position: bottom left !important;
}

/* Medium Devices, Desktops */
@media only screen and (min-width: 1024px) {
  .parallax-banner,
  .intro-text-lax {
    height: 100vh !important;
  }
}

.nav-body {
  padding-top: 0rem !important;
}

.leading-relaxed {
  line-height: 1.625 !important;
}

.masonry-brick:hover {
  cursor: pointer;
}

.masonry-brick {
  /* Used to lazy load the images one at a time */
  min-width: 325px;
}

.masonry-brick:nth-child(4n + 1) {
  width: 300px;
}

.masonry-brick:nth-child(4n + 1):nth-child(4n + 2) {
  width: 325px !important;
}
.masonry-brick:nth-child(4n + 1):nth-child(4n + 3) {
  width: 170px !important;
}
.masonry-brick:nth-child(4n + 1):nth-child(4n + 4) {
  width: 380px !important;
}

.hover-underline-animation:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: var(--nav-color);
  transform-origin: bottom right;
  transition: transform 0.2s ease-out;
}

#publications .hover-underline-animation:after {
  background-color: rgb(252, 165, 165);
}

.hover-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.scroll-animation {
  width: 1px;
  height: 400px;
}

.scroll-animation:after {
  animation-name: scroll-animation;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  content: "";
  position: absolute;
  width: 1px;
  transform: scaleX(0);
  height: 100%;
  bottom: 0;
  left: 0;
  background-color: black;
  transform-origin: bottom right;
  transition: transform 0.2s ease-out;
}

@keyframes scroll-animation {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
    transform-origin: bottom left;
  }
  100% {
    transform: scale(0);
  }
}

#hero-text .letter {
  display: inline-block;
  line-height: 0em;
}

.scroll-text {
  animation: scroll-text 3s infinite;
  transition: all ease-in-out;
}

@keyframes scroll-text {
  0% {
    transform: translateY(-20px) rotate(-90deg);
  }
  60% {
    transform: translateY(0) rotate(-90deg);
  }
  100% {
    transform: translateY(-20px) rotate(-90deg);
  }
}

.c-scroll-icon {
  width: 21px;
  height: 100px;
  transform: translate3d(-50%, 0%, 0);
  backface-visibility: hidden;
}
.c-scroll-icon .c-scroll-icon-line-mask {
  position: relative;
  overflow: hidden;
  width: 21px;
  height: 100px;
  margin: 0 auto;
}
.c-scroll-icon .c-scroll-icon-line {
  position: absolute;
  display: inline-block;
  width: 1px;
  height: 150px;
  border-left: 1px solid #fff;
  top: -150px;
  left: 10px;
  animation: scroll-line 3s infinite;
  transition: all cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
@keyframes scroll-line {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(0, 280px, 0);
  }
}

/* Loading Component */

#loading::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0));
  animation: shimmer 3s infinite;
  content: "";
}
@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
